
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { computed, ComputedRef, defineAsyncComponent, defineComponent, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'DashboardIndex',
  components: {
    ToolBox: defineAsyncComponent(() => import('../components/ToolBox.vue')),
    DashboardDrawer: defineAsyncComponent(() => import('./components/Drawer.vue')),
    DashboardView: defineAsyncComponent(() => import('./components/View.vue')),
    DashboardAppBar: defineAsyncComponent(() => import('./components/AppBar.vue')),
    DashboardFooter: defineAsyncComponent(() => import('./components/Footer.vue'))
  },
  setup () {
    const { proxy }: any = getCurrentInstance()
    const router = useRouter()
    const store = useStore()
    const isCollapse: ComputedRef<boolean> = computed(() => {
      return store.getters.GET_MENU_BAR
    })
    const hasLogin = computed(() => {
      return store.getters.GET_LOGIN
    })

    const wideScreen = computed<boolean>(() => {
      return store.getters.GET_WIDE_SCREEN
    })

    const checkLogin = (): void => {
      if (process.env.NODE_ENV === 'development') {
        console.log(store.getters.GET_LOGIN)
      }
      if (!localStorage.getItem('s')) {
        ElMessage.warning({
          message: '请登录后操作~',
          type: 'warning'
        })
        setTimeout(() => {
          proxy.$nextTick(() => {
            const routerUrl = router.resolve({ path: '/login' })
            location.href = routerUrl.href
            store.commit('Logout')
          })
        }, 800)
      }
    }
    checkLogin()

    return {
      isCollapse,
      wideScreen,
      hasLogin
    }
  }
})
