<template>
  <el-container v-show="hasLogin" :class="{ widescreen: !wideScreen }">
    <el-header>
      <dashboard-app-bar />
    </el-header>
    <el-container style="height: calc(100vh - 60px)">
      <el-aside :style="{'width': !isCollapse ? '64px' : '200px'}" :class="{ anim : isCollapse }">
        <dashboard-drawer />
      </el-aside>
      <el-container>
        <dashboard-view />
        <el-footer>
          <dashboard-footer />
        </el-footer>
      </el-container>
    </el-container>
  </el-container>
  <teleport to='body'>
    <tool-box></tool-box>
  </teleport>
</template>

<script lang="ts">
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { computed, ComputedRef, defineAsyncComponent, defineComponent, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'DashboardIndex',
  components: {
    ToolBox: defineAsyncComponent(() => import('../components/ToolBox.vue')),
    DashboardDrawer: defineAsyncComponent(() => import('./components/Drawer.vue')),
    DashboardView: defineAsyncComponent(() => import('./components/View.vue')),
    DashboardAppBar: defineAsyncComponent(() => import('./components/AppBar.vue')),
    DashboardFooter: defineAsyncComponent(() => import('./components/Footer.vue'))
  },
  setup () {
    const { proxy }: any = getCurrentInstance()
    const router = useRouter()
    const store = useStore()
    const isCollapse: ComputedRef<boolean> = computed(() => {
      return store.getters.GET_MENU_BAR
    })
    const hasLogin = computed(() => {
      return store.getters.GET_LOGIN
    })

    const wideScreen = computed<boolean>(() => {
      return store.getters.GET_WIDE_SCREEN
    })

    const checkLogin = (): void => {
      if (process.env.NODE_ENV === 'development') {
        console.log(store.getters.GET_LOGIN)
      }
      if (!localStorage.getItem('s')) {
        ElMessage.warning({
          message: '请登录后操作~',
          type: 'warning'
        })
        setTimeout(() => {
          proxy.$nextTick(() => {
            const routerUrl = router.resolve({ path: '/login' })
            location.href = routerUrl.href
            store.commit('Logout')
          })
        }, 800)
      }
    }
    checkLogin()

    return {
      isCollapse,
      wideScreen,
      hasLogin
    }
  }
})
</script>

<style lang="scss" scoped>
  :deep(.el-footer) {
    padding: 0;
  }
  .el-header {
    position: relative;
    background: #FFF;
    overflow: hidden;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
  }
  .menu {
    width: 200px;
  }
  .body {
    width: calc(100% - 200px);
  }
  .el-footer {
    background: #FFF;
  }
  .el-menu {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
  .anim.el-aside {
    overflow-x: hidden;
    transition: all .6s;
  }
  @media screen and (max-width: 768px) {
    .el-aside {
      display: none;
    }
  }
</style>

<style lang="scss">
  .widescreen {
    max-width: 1000px;
    margin: 0 auto;
    @at-root #app {
      background: #eee;
    }
  }
</style>
